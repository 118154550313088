/** @jsxImportSource theme-ui */
import React from 'react';
import PropTypes from 'prop-types';
import theme from '../theme';

const Logo = ({ color, width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.0"
    sx={{ width, height }}
    viewBox="0 0 2400 1200"
    preserveAspectRatio="xMidYMid meet"
  >
    <g transform="translate(-300,1900) scale(0.100000,-0.100000)" fill={theme.colors[color] ?? color} stroke="none">
      <path d="M15824 15687 c-2 -7 -3 -76 -2 -152 l3 -140 215 0 215 0 0 150 0 150 -213 3 c-168 2 -214 0 -218 -11z" />
      <path d="M16895 15688 c-3 -7 -4 -391 -3 -853 l3 -840 203 -3 202 -2 0 855 0 855 -200 0 c-151 0 -202 -3 -205 -12z" />
      <path d="M17955 15688 c-3 -7 -4 -391 -3 -853 l3 -840 203 -3 202 -2 0 855 0 855 -200 0 c-151 0 -202 -3 -205 -12z" />
      <path d="M19004 15687 c-2 -7 -3 -76 -2 -152 l3 -140 215 0 215 0 0 150 0 150 -213 3 c-168 2 -214 0 -218 -11z" />
      <path d="M2290 15465 l0 -155 190 0 190 0 0 -387 c0 -219 -5 -416 -10 -452 -20 -118 -64 -170 -170 -198 -53 -14 -165 -17 -206 -4 -16 5 -49 14 -74 22 -25 7 -49 16 -54 21 -6 4 -27 8 -48 8 l-38 0 2 -172 3 -173 55 -8 c99 -15 450 -20 515 -7 239 47 397 188 441 395 10 45 13 207 14 663 l0 602 -405 0 -405 0 0 -155z" />
      <path d="M12909 15613 c-3 -21 4 -69 11 -78 4 -5 10 -23 13 -40 3 -16 10 -48 17 -70 6 -22 16 -56 21 -75 6 -19 14 -48 19 -65 8 -31 26 -93 55 -200 22 -79 30 -108 49 -177 30 -108 65 -239 95 -350 12 -43 30 -113 42 -155 32 -118 69 -260 88 -338 l18 -70 233 -3 c129 -1 237 1 241 5 5 5 24 71 44 148 123 476 226 859 235 868 4 5 10 5 13 0 3 -4 51 -175 106 -378 55 -204 109 -401 120 -440 10 -38 25 -95 32 -125 7 -30 17 -61 22 -67 7 -10 67 -13 238 -13 l229 0 11 28 c6 15 21 68 34 117 13 50 33 125 44 168 12 42 30 110 40 150 32 118 72 265 102 377 71 257 137 497 159 575 9 31 23 79 41 143 20 73 37 67 -205 67 l-219 0 -13 -55 c-7 -30 -18 -75 -23 -100 -5 -25 -19 -83 -30 -130 -10 -47 -57 -248 -103 -448 -76 -333 -84 -360 -96 -335 -7 15 -68 253 -137 528 -69 275 -129 510 -135 523 -9 22 -12 22 -214 22 -229 0 -215 5 -235 -76 -99 -400 -265 -1004 -274 -1001 -9 3 -110 437 -177 762 -12 61 -26 124 -30 140 -4 17 -13 57 -20 90 -7 33 -14 66 -16 73 -3 9 -56 12 -224 12 -121 0 -220 -3 -221 -7z" />
      <path d="M4030 15248 c-30 -5 -86 -14 -125 -19 -38 -6 -85 -16 -102 -21 l-33 -10 0 -139 c0 -118 2 -141 16 -146 14 -5 33 -1 141 33 182 58 403 57 504 -2 36 -22 69 -76 69 -115 0 -31 -9 -33 -155 -44 -303 -22 -470 -73 -574 -176 -46 -46 -57 -63 -90 -142 -6 -15 -11 -73 -11 -127 0 -83 4 -108 24 -153 115 -261 472 -316 743 -116 29 22 54 39 57 39 3 0 6 -26 8 -57 l3 -58 195 0 195 0 3 440 c3 460 0 506 -41 593 -39 84 -146 166 -254 194 -144 37 -430 50 -573 26z m470 -822 l0 -125 -27 -18 c-66 -45 -129 -63 -215 -63 -79 0 -147 26 -168 66 -18 33 -18 116 0 149 15 29 77 68 125 80 43 10 203 32 248 34 l37 1 0 -124z" />
      <path d="M6235 15246 c-62 -17 -165 -69 -218 -109 -26 -21 -49 -37 -52 -37 -2 0 -5 26 -7 58 l-3 57 -200 0 -200 0 0 -610 0 -610 200 0 200 0 3 435 2 435 63 30 c124 58 271 41 314 -37 16 -30 18 -71 23 -448 l5 -415 200 0 200 0 -4 415 c-2 228 -1 425 3 436 11 35 117 77 203 82 59 3 80 0 116 -17 83 -40 87 -67 87 -534 l0 -387 203 2 202 3 0 440 c-1 477 -4 515 -54 620 -46 96 -109 151 -210 184 -72 23 -179 28 -245 10 -71 -19 -178 -74 -259 -133 -78 -56 -107 -68 -107 -43 0 21 -99 116 -143 139 -93 47 -226 61 -322 34z" />
      <path d="M8680 15248 c-167 -35 -261 -85 -366 -194 -53 -55 -105 -139 -129 -209 -46 -131 -46 -362 0 -490 56 -156 195 -292 360 -350 178 -64 478 -74 670 -23 101 27 128 36 189 62 l38 17 -5 157 c-3 86 -6 158 -6 160 -3 11 -56 -3 -92 -26 -56 -35 -188 -87 -257 -101 -29 -6 -92 -11 -140 -11 -222 1 -356 83 -385 237 l-6 33 452 2 452 3 3 60 c5 94 -17 267 -44 342 -55 151 -161 257 -309 308 -95 33 -319 45 -425 23z m241 -273 c24 -9 54 -26 68 -38 32 -30 61 -94 61 -139 l0 -38 -250 0 -249 0 5 33 c16 88 81 163 164 185 55 15 153 14 201 -3z" />
      <path d="M10395 15251 c-16 -5 -55 -14 -85 -21 -147 -34 -286 -135 -341 -248 -20 -39 -23 -62 -23 -142 1 -118 26 -182 101 -252 91 -84 146 -105 433 -159 170 -33 200 -49 200 -114 0 -37 -40 -69 -104 -85 -67 -16 -225 -9 -316 15 -60 16 -211 84 -271 122 -14 9 -33 13 -42 10 -15 -6 -17 -26 -17 -160 0 -136 2 -156 18 -170 10 -10 24 -17 30 -17 7 0 20 -4 30 -9 16 -8 35 -14 140 -41 92 -23 204 -32 337 -27 183 7 262 24 372 78 167 84 260 244 233 403 -33 192 -158 276 -488 331 -188 32 -237 54 -248 112 -17 89 159 139 346 99 105 -22 197 -60 286 -116 12 -7 29 -10 38 -7 14 6 16 27 16 161 l0 155 -32 15 c-18 7 -44 17 -58 21 -14 3 -41 11 -60 16 -98 26 -206 39 -330 38 -74 0 -148 -4 -165 -8z" />
      <path d="M20350 15248 c-30 -5 -86 -14 -125 -19 -38 -6 -85 -16 -102 -21 l-33 -10 0 -139 c0 -118 2 -141 16 -146 14 -5 33 -1 141 33 186 59 407 57 506 -5 37 -23 67 -73 67 -114 0 -27 -4 -28 -160 -42 -317 -28 -471 -76 -571 -177 -45 -45 -56 -63 -88 -141 -6 -15 -11 -73 -11 -127 0 -83 4 -108 24 -153 64 -146 214 -237 387 -237 127 0 260 45 362 123 26 21 49 37 52 37 2 0 5 -26 7 -57 l3 -58 198 -3 197 -2 0 452 c0 472 -3 502 -45 592 -38 79 -147 161 -252 188 -144 37 -430 50 -573 26z m470 -822 l0 -125 -27 -18 c-97 -65 -239 -84 -327 -43 -52 25 -68 52 -69 119 -2 112 54 147 288 180 33 5 77 9 98 10 l37 1 0 -124z" />
      <path d="M22555 15246 c-62 -17 -165 -69 -218 -109 -26 -21 -49 -37 -52 -37 -2 0 -5 26 -7 58 l-3 57 -200 0 -200 0 0 -610 0 -610 202 -3 203 -2 2 437 3 437 60 30 c118 60 269 43 312 -36 16 -30 18 -71 23 -448 l5 -415 200 0 200 0 -4 415 c-2 228 -1 425 3 436 11 35 117 77 203 82 59 3 80 0 116 -17 83 -40 87 -67 87 -534 l0 -387 203 2 202 3 0 440 c0 477 -4 515 -54 620 -46 96 -109 151 -210 184 -72 23 -179 28 -245 10 -71 -19 -178 -74 -259 -133 -78 -56 -107 -68 -107 -43 0 21 -99 116 -143 139 -93 47 -226 61 -322 34z" />
      <path d="M24920 15248 c-157 -33 -262 -86 -347 -175 -69 -73 -87 -122 -87 -233 0 -81 4 -103 26 -150 41 -89 123 -158 235 -199 54 -19 107 -31 273 -62 177 -34 209 -54 195 -125 -6 -36 -25 -51 -90 -71 -86 -27 -280 -9 -400 36 -56 22 -125 56 -164 83 -24 16 -53 28 -65 26 -20 -3 -21 -9 -24 -164 l-2 -160 25 -12 c72 -34 118 -47 293 -81 70 -14 326 -14 394 0 154 31 258 80 336 157 97 97 135 204 112 319 -35 176 -150 267 -395 312 -275 50 -319 65 -336 109 -49 129 230 178 469 81 54 -22 80 -35 120 -62 23 -16 53 -27 65 -25 22 3 22 6 25 160 l2 156 -31 16 c-18 9 -38 16 -46 16 -7 0 -21 4 -31 9 -9 5 -44 15 -77 21 -33 6 -79 16 -102 21 -53 11 -314 10 -373 -3z" />
      <path d="M15835 15208 c-3 -7 -4 -283 -3 -613 l3 -600 203 -3 202 -2 -2 612 -3 613 -198 3 c-152 2 -199 0 -202 -10z" />
      <path d="M19015 15208 c-3 -7 -4 -283 -3 -613 l3 -600 203 -3 202 -2 0 615 0 615 -200 0 c-151 0 -202 -3 -205 -12z" />
      <path d="M11570 14725 l0 -155 425 0 426 0 -3 153 -3 152 -422 3 -423 2 0 -155z" />
      <path d="M14940 13329 c-140 -25 -178 -33 -275 -60 -46 -13 -158 -49 -180 -59 -211 -88 -298 -140 -423 -255 -56 -51 -152 -183 -152 -208 0 -7 -3 -17 -7 -21 -19 -19 -33 -91 -33 -173 0 -114 25 -177 98 -245 44 -42 65 -54 145 -87 51 -20 187 -14 267 13 79 27 179 88 226 138 60 63 114 157 143 247 28 88 22 356 -9 356 -13 0 -14 -19 -12 -125 5 -171 -18 -258 -100 -381 -63 -96 -189 -169 -290 -169 -122 0 -207 122 -195 278 8 99 20 140 63 228 67 136 190 250 380 352 89 48 239 96 380 121 114 21 349 10 419 -19 11 -5 30 -12 43 -15 32 -9 28 -16 -26 -50 -93 -59 -238 -174 -309 -247 -124 -126 -189 -225 -383 -583 -182 -336 -354 -548 -498 -611 -20 -9 -47 -21 -59 -26 -12 -6 -60 -11 -107 -11 -81 -1 -87 1 -123 30 -32 25 -38 37 -41 74 -2 38 0 45 20 54 28 12 57 69 57 110 0 43 -29 96 -64 116 -19 11 -52 18 -93 18 -61 1 -64 0 -106 -42 -49 -49 -63 -100 -50 -184 13 -88 82 -162 179 -193 22 -7 50 -16 63 -21 33 -13 273 -11 329 2 132 31 139 33 238 80 108 51 162 89 268 189 126 118 230 262 356 493 81 149 95 175 116 212 51 92 68 121 86 147 11 14 19 29 19 32 0 29 249 336 272 336 16 0 56 -50 78 -97 48 -102 1 -296 -97 -399 -34 -36 -107 -86 -148 -102 -35 -13 -133 -31 -190 -35 -53 -3 -55 -29 -2 -38 129 -21 141 -112 40 -310 -94 -183 -105 -217 -105 -307 1 -62 6 -88 22 -118 31 -55 46 -70 96 -93 50 -24 173 -29 221 -10 193 78 327 194 553 479 28 36 62 78 75 95 13 16 21 33 19 37 -12 19 -32 4 -74 -54 -25 -34 -49 -66 -55 -72 -5 -6 -39 -45 -75 -86 -155 -179 -335 -320 -408 -320 -25 0 -62 41 -62 70 0 22 29 118 50 165 53 118 65 254 29 330 -29 62 -89 127 -143 154 -25 13 -43 27 -41 31 3 4 42 11 87 14 185 16 327 70 422 162 77 75 98 126 98 240 0 50 -5 100 -10 110 -5 11 -16 32 -23 48 -8 16 -36 51 -63 78 -44 43 -64 57 -133 92 -13 6 -22 15 -20 20 5 15 80 33 172 42 69 7 90 12 90 24 0 24 -222 26 -301 4 -68 -20 -111 -21 -209 -4 -90 15 -453 21 -525 9z" />
      <path d="M4820 13309 c-225 -25 -386 -56 -575 -111 -191 -56 -363 -146 -468 -247 -50 -47 -127 -151 -127 -171 0 -5 -4 -18 -9 -28 -32 -63 -43 -212 -20 -269 34 -82 47 -103 98 -153 51 -50 92 -76 174 -109 39 -15 185 -14 249 3 171 45 335 164 395 287 42 87 47 112 46 214 -1 105 -20 175 -48 175 -17 0 -27 -14 -18 -24 4 -4 13 -28 20 -54 31 -119 3 -221 -84 -307 -55 -54 -168 -118 -241 -137 -178 -46 -294 -44 -409 8 -107 49 -160 157 -133 272 19 78 42 118 105 177 48 46 202 135 233 135 7 0 20 4 30 9 24 13 139 42 232 60 249 47 651 64 973 41 78 -6 212 -12 297 -15 85 -2 213 -7 283 -11 108 -5 137 -4 185 11 76 24 112 63 112 121 0 37 -4 46 -32 66 -56 40 -79 47 -193 53 -205 10 -990 13 -1075 4z" />
      <path d="M11095 13268 c-88 -12 -92 -14 -122 -68 -14 -25 -34 -57 -44 -72 -11 -14 -19 -29 -19 -33 0 -3 -13 -26 -30 -50 -16 -24 -30 -46 -30 -48 0 -3 -18 -35 -41 -72 -22 -37 -49 -82 -59 -99 -10 -17 -54 -91 -99 -165 -44 -73 -81 -135 -81 -138 0 -3 -8 -17 -19 -31 -10 -15 -28 -44 -40 -64 -36 -65 -57 -101 -88 -153 -30 -50 -130 -253 -143 -290 -39 -108 -40 -171 -6 -229 55 -94 178 -114 310 -52 39 19 99 55 132 80 76 57 244 230 309 319 28 38 60 81 72 95 13 15 23 33 23 40 0 6 4 12 9 12 5 0 11 9 14 20 4 15 0 20 -14 20 -10 0 -19 -4 -19 -10 0 -11 -104 -151 -160 -215 -50 -58 -198 -205 -245 -244 -45 -37 -130 -81 -157 -81 -9 0 -24 10 -32 23 -14 20 -14 29 -1 77 16 60 87 209 147 309 21 34 38 65 38 66 0 2 23 41 51 87 29 46 60 97 70 115 10 17 33 56 52 85 19 29 53 85 77 123 24 39 63 100 87 137 23 37 43 70 43 73 0 3 23 39 50 80 28 41 50 77 50 80 0 4 14 26 30 50 17 24 30 48 30 54 0 6 5 11 10 11 6 0 10 5 10 11 0 5 18 37 40 69 59 87 57 90 -47 88 -49 0 -119 -5 -158 -10z" />
      <path d="M5370 13024 c-122 -41 -252 -102 -347 -163 -113 -73 -265 -239 -322 -353 -13 -26 -19 -28 -72 -28 -39 0 -63 -5 -73 -16 -24 -24 -10 -34 45 -34 27 0 49 -2 49 -4 0 -3 -22 -41 -50 -86 -27 -45 -50 -83 -50 -85 0 -3 -54 -97 -85 -146 -75 -121 -198 -258 -285 -316 -143 -96 -384 -100 -445 -7 -21 32 -31 84 -16 84 17 0 51 32 67 62 8 15 14 48 14 73 0 83 -59 135 -152 135 -57 0 -103 -26 -139 -79 -20 -31 -24 -47 -23 -112 1 -68 4 -80 33 -122 35 -51 74 -83 141 -114 71 -34 95 -37 270 -36 167 1 247 8 265 24 5 5 19 9 32 9 13 0 31 4 41 9 9 5 28 13 42 19 267 105 481 318 660 657 l26 50 78 8 c43 4 81 6 83 3 3 -2 -9 -33 -25 -68 -73 -155 -100 -227 -104 -277 -4 -49 15 -48 47 1 90 138 269 338 433 481 23 21 42 42 42 47 0 6 -52 10 -126 10 -140 0 -135 2 -205 -91 -22 -28 -38 -38 -80 -47 -78 -16 -119 -22 -119 -17 0 14 131 219 183 288 82 107 238 247 276 247 5 0 13 5 16 10 11 17 -47 10 -125 -16z" />
      <path d="M9945 13008 c-126 -69 -127 -241 -1 -303 54 -28 151 -13 184 28 28 35 52 92 52 125 0 49 -47 124 -91 146 -37 18 -114 21 -144 4z" />
      <path d="M20998 12990 c-83 -69 -92 -167 -23 -248 30 -35 69 -52 120 -52 67 0 114 30 146 92 58 113 -23 238 -154 238 -44 0 -59 -5 -89 -30z" />
      <path d="M6280 12530 c-14 -4 -45 -14 -70 -21 -144 -43 -305 -164 -386 -289 -28 -43 -37 -61 -62 -120 -68 -168 -23 -366 96 -415 53 -23 163 -19 220 7 73 34 132 76 196 140 32 33 60 57 63 54 3 -2 7 -24 10 -48 13 -117 74 -168 198 -168 64 0 86 5 138 30 34 17 78 43 97 58 40 31 193 182 230 227 55 66 76 93 100 125 14 18 43 56 66 84 22 28 46 61 53 74 19 36 -13 28 -40 -10 -13 -18 -29 -39 -36 -46 -7 -8 -26 -30 -41 -50 -15 -21 -29 -39 -32 -42 -3 -3 -25 -30 -50 -60 -109 -134 -264 -275 -338 -305 -43 -19 -60 -19 -78 -1 -16 16 -19 86 -5 114 5 9 28 58 51 107 24 50 56 109 72 132 15 23 28 44 28 47 0 2 23 37 50 78 163 237 189 278 180 283 -6 4 -78 3 -160 -1 -162 -7 -164 -8 -191 -71 -8 -18 -17 -33 -21 -33 -4 0 -15 16 -24 36 -11 22 -36 48 -63 65 -41 27 -52 29 -135 28 -50 0 -102 -4 -116 -9z m236 -75 c14 -14 17 -33 16 -93 -2 -80 -6 -98 -53 -200 -85 -186 -289 -402 -379 -402 -10 0 -28 13 -39 29 -56 79 11 317 142 506 42 60 133 148 172 167 48 22 115 19 141 -7z" />
      <path d="M8080 12528 c-66 -28 -119 -55 -155 -79 -43 -30 -146 -127 -209 -199 -57 -64 -41 -33 62 119 51 73 89 138 86 142 -9 15 -297 11 -317 -3 -9 -7 -29 -38 -45 -68 -16 -30 -39 -72 -50 -92 -12 -21 -32 -55 -43 -75 -12 -21 -30 -50 -40 -65 -11 -14 -19 -29 -19 -32 0 -4 -22 -40 -50 -81 -27 -41 -50 -77 -50 -81 0 -3 -8 -18 -19 -32 -10 -15 -28 -44 -40 -64 -11 -21 -31 -55 -43 -75 -40 -71 -68 -128 -68 -142 0 -11 13 -13 68 -7 37 3 107 9 155 13 68 5 90 10 95 22 6 16 151 237 177 269 234 293 400 452 471 452 77 0 51 -101 -83 -325 -69 -115 -118 -193 -154 -247 -22 -31 -39 -60 -39 -63 0 -4 -14 -27 -31 -51 -41 -60 -34 -78 29 -71 26 4 93 9 150 13 97 7 104 8 122 34 11 16 20 31 20 34 0 22 209 310 305 421 209 243 345 320 345 196 0 -28 -12 -51 -50 -101 -63 -81 -182 -257 -196 -290 -29 -65 -34 -85 -34 -135 0 -71 31 -130 89 -167 38 -24 55 -28 112 -28 65 0 79 3 156 37 76 33 218 166 340 318 35 44 70 87 76 95 7 8 23 31 37 50 13 19 35 46 47 60 24 25 31 60 13 60 -11 0 -15 -4 -90 -100 -119 -152 -159 -199 -235 -279 -92 -96 -203 -171 -254 -171 -27 0 -44 26 -43 65 1 33 28 88 69 142 79 104 102 136 143 203 51 84 66 118 76 173 22 128 -56 217 -191 217 -36 0 -74 -4 -85 -10 -11 -5 -29 -14 -40 -20 -11 -5 -30 -16 -42 -22 -41 -23 -142 -112 -207 -182 -41 -44 -61 -60 -56 -45 11 33 18 145 11 173 -7 30 -33 59 -76 85 -27 16 -50 21 -105 20 -38 0 -81 -5 -95 -11z" />
      <path d="M16755 12530 c-11 -4 -40 -14 -65 -21 -25 -7 -79 -30 -120 -51 -181 -92 -298 -217 -369 -391 -6 -15 -11 -66 -11 -113 0 -148 58 -229 195 -273 41 -13 230 -11 290 3 141 34 339 169 510 347 111 116 215 243 215 263 0 4 -9 6 -19 6 -13 0 -40 -26 -75 -72 -66 -87 -158 -184 -246 -258 -36 -30 -67 -58 -70 -61 -3 -3 -32 -23 -65 -45 -119 -79 -239 -117 -303 -95 -106 36 -126 75 -123 239 1 40 3 42 30 42 17 0 33 5 36 10 3 6 19 10 35 10 16 0 39 4 52 9 13 5 43 14 68 21 25 7 53 16 62 21 10 5 25 9 33 9 8 0 23 4 33 9 9 5 52 26 95 46 90 43 168 113 183 165 22 76 -21 145 -112 176 -43 15 -226 18 -259 4z m133 -93 c5 -83 -65 -194 -168 -264 -57 -38 -128 -73 -151 -73 -10 0 -21 -4 -24 -10 -3 -5 -13 -10 -21 -10 -51 0 102 270 206 363 51 46 83 57 130 47 22 -6 25 -13 28 -53z" />
      <path d="M19760 12531 c-8 -5 -28 -15 -45 -22 -29 -13 -98 -57 -140 -89 -30 -22 -164 -158 -213 -214 -23 -26 -42 -45 -42 -41 0 7 140 216 150 225 4 3 21 27 39 53 41 60 36 82 -17 74 -20 -4 -90 -9 -155 -13 l-118 -7 -34 -59 c-19 -33 -35 -61 -35 -64 0 -3 -13 -25 -30 -49 -16 -24 -30 -46 -30 -48 0 -3 -18 -35 -41 -72 -39 -64 -49 -81 -100 -172 -12 -21 -30 -50 -40 -65 -11 -14 -19 -29 -19 -33 0 -4 -8 -19 -19 -33 -10 -15 -29 -45 -41 -67 -13 -22 -34 -60 -47 -84 -17 -28 -21 -47 -15 -53 7 -7 60 -6 156 3 l146 13 25 38 c14 21 25 40 25 43 0 12 165 251 190 275 3 3 25 30 49 60 24 30 94 105 156 165 141 141 218 181 258 137 36 -39 14 -90 -93 -217 -79 -94 -150 -198 -150 -219 0 -8 -4 -18 -10 -21 -5 -3 -10 -41 -10 -85 0 -98 24 -150 89 -192 39 -24 55 -28 118 -28 40 0 81 4 91 9 9 5 34 16 54 25 73 32 255 200 358 332 8 10 31 39 50 64 19 24 45 56 58 70 13 14 31 37 40 53 9 15 20 27 24 27 4 0 8 11 8 25 0 35 -14 31 -47 -12 -15 -21 -34 -44 -41 -51 -7 -8 -25 -30 -40 -50 -15 -21 -31 -42 -37 -48 -5 -6 -30 -35 -55 -65 -70 -84 -210 -222 -260 -256 -25 -17 -62 -36 -82 -43 -33 -12 -39 -12 -58 5 -11 10 -20 26 -20 36 0 28 46 122 83 171 18 23 48 66 67 93 19 28 42 59 52 69 10 11 18 24 18 29 0 5 9 22 21 38 29 42 59 127 59 170 0 43 -21 98 -47 121 -48 42 -93 58 -166 57 -40 0 -79 -4 -87 -8z" />
      <path d="M21880 12530 c-14 -5 -45 -14 -70 -21 -25 -7 -83 -30 -130 -53 -68 -32 -101 -56 -165 -121 -111 -111 -165 -228 -165 -360 0 -50 4 -68 33 -135 20 -45 109 -124 157 -139 25 -7 57 -18 71 -23 28 -10 245 -6 294 6 98 24 226 97 308 175 102 96 187 257 187 351 l0 34 28 -26 c100 -95 233 -92 351 8 22 20 41 42 41 49 0 29 -22 25 -59 -8 -44 -40 -70 -56 -121 -74 -92 -33 -161 7 -258 153 -48 72 -103 122 -169 153 -27 13 -56 27 -65 32 -22 11 -234 10 -268 -1z m232 -57 c33 -41 40 -74 28 -142 -7 -37 -16 -75 -20 -86 -4 -11 -13 -38 -20 -60 -58 -183 -230 -416 -343 -464 -62 -27 -75 -26 -112 3 -29 23 -30 27 -29 100 1 71 11 125 36 186 6 14 14 34 18 45 28 72 116 234 140 255 3 3 24 27 45 54 56 69 146 126 200 126 28 0 47 -6 57 -17z" />
      <path d="M23680 12531 c-8 -5 -28 -15 -45 -22 -29 -13 -98 -57 -140 -89 -30 -23 -176 -169 -218 -219 -20 -24 -37 -41 -37 -37 0 7 72 114 169 251 28 39 50 78 50 89 1 17 -4 18 -51 13 -29 -4 -102 -9 -161 -13 l-108 -7 -36 -61 c-33 -57 -44 -76 -92 -158 -10 -18 -33 -58 -51 -88 -33 -56 -53 -90 -91 -157 -12 -21 -30 -50 -40 -65 -11 -14 -19 -29 -19 -33 0 -4 -8 -19 -19 -33 -10 -15 -29 -45 -41 -67 -13 -22 -34 -59 -48 -83 -17 -30 -21 -47 -14 -54 7 -7 58 -6 160 3 121 10 152 16 156 28 30 92 255 390 411 546 171 172 247 209 289 142 17 -27 -29 -123 -84 -177 -47 -46 -170 -222 -170 -244 0 -8 -4 -18 -10 -21 -5 -3 -10 -41 -10 -85 0 -98 24 -150 89 -192 39 -24 55 -28 118 -28 40 0 81 4 91 9 9 5 34 17 54 25 21 9 59 33 85 52 53 39 230 218 272 274 14 19 32 41 39 48 7 8 25 30 40 50 15 21 30 39 33 42 3 3 22 27 42 53 40 52 46 77 21 77 -18 0 -30 -12 -64 -60 -13 -19 -30 -41 -37 -48 -7 -8 -26 -30 -41 -50 -15 -21 -29 -39 -32 -42 -3 -3 -30 -34 -60 -70 -124 -147 -246 -253 -322 -280 -33 -12 -39 -12 -58 5 -11 10 -20 26 -20 36 0 30 47 119 106 199 161 222 194 284 194 369 0 26 -7 61 -15 78 -18 35 -69 83 -89 83 -8 0 -18 5 -21 10 -7 12 -153 12 -175 1z" />
      <path d="M9713 12513 c-67 -4 -73 -8 -122 -95 -11 -21 -30 -54 -41 -73 -11 -19 -30 -52 -41 -72 -12 -21 -30 -50 -40 -65 -11 -14 -19 -29 -19 -32 0 -3 -13 -27 -28 -53 -58 -100 -77 -162 -77 -253 1 -73 4 -90 25 -122 37 -56 85 -78 166 -78 131 0 208 43 374 210 105 105 123 125 193 220 27 36 54 72 61 80 46 57 75 109 64 116 -12 8 -37 -19 -90 -96 -99 -143 -290 -348 -378 -407 -63 -42 -116 -60 -138 -47 -20 13 -29 96 -14 128 43 94 82 171 114 225 21 34 38 65 38 67 0 3 27 45 60 94 33 49 60 92 60 95 0 3 20 36 45 73 25 37 45 72 45 79 0 13 -95 15 -257 6z" />
      <path d="M11571 12509 c-70 -8 -101 -21 -101 -41 0 -5 -36 -62 -80 -128 -88 -131 -106 -161 -159 -265 -51 -100 -61 -133 -61 -207 0 -132 59 -198 177 -198 108 0 229 60 351 175 l74 70 -30 -50 c-66 -111 -117 -193 -153 -247 -22 -31 -39 -59 -39 -62 0 -3 -14 -25 -30 -48 -17 -24 -48 -68 -69 -98 -67 -96 -187 -225 -257 -278 -61 -45 -93 -64 -182 -105 -35 -17 -184 -15 -218 3 -81 42 -114 114 -114 249 0 103 10 143 49 193 33 42 76 58 153 58 76 0 92 17 35 38 -43 15 -229 16 -276 0 -113 -36 -185 -90 -230 -171 -16 -29 -21 -55 -21 -112 0 -40 5 -77 10 -80 6 -3 10 -15 10 -25 0 -28 97 -121 155 -149 106 -50 120 -53 285 -58 141 -5 253 11 348 48 12 5 32 13 45 18 51 20 197 96 197 103 0 5 6 8 13 8 6 0 28 14 47 30 19 16 37 30 41 30 9 0 177 174 216 225 66 85 111 154 159 244 8 15 45 41 96 66 122 61 153 80 257 158 143 108 225 185 329 307 26 30 55 63 65 74 24 28 21 46 -8 46 -15 0 -25 -6 -25 -14 0 -8 -3 -16 -7 -18 -5 -1 -28 -28 -53 -58 -58 -70 -128 -141 -210 -210 -36 -30 -67 -57 -70 -60 -38 -38 -186 -137 -269 -179 -67 -35 -63 -35 -41 5 12 19 47 76 80 124 33 49 60 92 60 95 0 3 32 53 70 110 39 57 70 107 70 110 0 3 23 39 50 80 28 41 50 77 50 80 0 4 14 26 30 50 17 24 30 46 30 49 0 3 -71 6 -158 6 -176 0 -169 2 -211 -72 -54 -96 -65 -115 -82 -140 -11 -14 -19 -28 -19 -31 0 -15 -108 -178 -159 -240 -157 -193 -313 -297 -357 -239 -29 38 -11 99 68 231 21 34 38 65 38 67 0 3 54 85 120 185 136 202 161 244 153 253 -9 8 -153 5 -242 -5z" />
      <path d="M17630 12400 c-44 -66 -80 -122 -80 -126 0 -3 -8 -18 -19 -32 -38 -54 -106 -182 -131 -247 -4 -11 -13 -33 -19 -48 -18 -45 -13 -132 10 -181 31 -68 81 -96 169 -96 60 0 82 5 143 34 89 43 112 61 207 156 l75 75 0 -77 c0 -70 3 -79 31 -114 46 -57 79 -72 154 -73 62 -1 112 11 155 36 11 7 35 20 52 30 64 35 234 192 313 288 14 17 45 55 70 85 25 30 47 57 50 60 26 23 80 100 80 114 0 33 -26 14 -82 -56 -31 -40 -65 -82 -75 -93 -10 -11 -39 -45 -65 -76 -170 -202 -365 -348 -413 -309 -49 40 9 176 189 444 67 98 126 186 131 194 6 9 18 25 28 36 9 11 17 26 17 33 0 7 5 13 10 13 6 0 10 7 10 15 0 13 -25 15 -161 15 -115 0 -164 -4 -172 -12 -7 -7 -25 -38 -41 -68 -16 -30 -39 -74 -52 -98 -25 -44 -163 -253 -173 -262 -3 -3 -20 -23 -36 -45 -133 -178 -309 -300 -360 -249 -41 42 7 164 145 370 28 40 50 76 50 79 0 3 43 70 95 147 52 78 95 145 95 149 0 5 -72 9 -160 9 l-160 0 -80 -120z" />
      <path d="M20783 12513 c-56 -4 -64 -11 -112 -95 -11 -21 -30 -54 -41 -73 -11 -19 -30 -52 -41 -72 -12 -21 -30 -50 -40 -65 -11 -14 -19 -29 -19 -32 0 -3 -13 -27 -28 -53 -58 -100 -77 -162 -77 -253 1 -73 4 -90 25 -122 39 -58 84 -78 174 -78 42 0 84 4 94 9 9 5 33 16 54 25 103 45 326 264 437 430 19 28 37 53 41 56 3 3 21 26 38 52 22 32 29 49 20 54 -12 8 -31 -13 -130 -146 -89 -119 -92 -122 -198 -230 -132 -135 -236 -200 -278 -174 -20 13 -29 96 -14 128 30 64 85 175 100 201 58 101 83 140 144 232 37 56 68 105 68 108 0 3 11 23 25 43 41 60 38 62 -82 60 -60 -1 -131 -3 -160 -5z" />
    </g>
  </svg>
);

Logo.propTypes = {
  color: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

Logo.defaultProps = {
  color: '#EE2E24',
  width: '400px',
  height: '150px',
};

export default Logo;
