/** @jsxImportSource theme-ui */
import React from 'react';
import { Box, Button, Container, Flex, Heading, Label, Text, Input } from 'theme-ui';
import { useForm } from 'react-hook-form';
import Logo from '../components/Logo';
import Header from '../components/Header';
import { useMutation } from 'react-query';
import { useNavigate, Link } from 'react-router-dom';
import { registerUser } from '../services';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import InputMask from 'react-input-mask';
import Footer from '../components/Footer';

const schema = yup
  .object({
    firstName: yup.string().required('First name is required'),
    lastName: yup.string().required('Last name is required'),
    email: yup.string().email().required('Email is required'),
    password: yup
      .string()
      .required('Password is required')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character'
      ),
    passwordConfirmation: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match'),
    currentCityResidence: yup.string().required(),
    countryResidence: yup.string().required(),
    phone: yup
      .string() // validate phone number based on country else us by default
      .trim()
      .matches(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/, 'incorrect format')
      .required(),
  })
  .required();

const Registration = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const navigate = useNavigate();
  const { mutate, isSuccess, isError } = useMutation('registerUser', (data) => registerUser(data));
  const onSubmit = ({ email, firstName, lastName, phone, currentCityResidence, countryResidence, password }) => {
    mutate(
      {
        email,
        firstName,
        lastName,
        phone,
        currentCityResidence,
        countryResidence,
        password,
      },
      {
        onSuccess: () => {
          navigate('/reg-success');
        },
      }
    );
  };

  return (
    <Container>
      <Flex width="100%" sx={{ flexDirection: 'column' }}>
        <Flex
          width="100%"
          sx={{
            flexDirection: 'column',
            justifyContent: 'flex-start',
            color: 'white',
            backgroundColor: 'gray90',
            minHeight: '30vh',
          }}
        >
          <Header />
          <Flex pt="5" px={['2', '4']} sx={{ justifyContent: 'flex-start' }}>
            <Logo color="white" />
          </Flex>
        </Flex>
      </Flex>
      <Flex sx={{ flexDirection: ['column'] }}>
        <Box p="4" width="100%">
          <Heading>Register</Heading>
        </Box>
        <Flex
          p={['2', '6']}
          sx={{
            width: '100%',
            backgroundColor: 'black',
            color: 'white',
            justifyContent: 'center',
          }}
        >
          {isError && (
            <Flex py="2" width="100%" sx={{ color: 'red' }}>
              Error occurred, try again
            </Flex>
          )}

          {isSuccess ? (
            <Box py="2" width="100%">
              Thanks your information was added
            </Box>
          ) : (
              <Flex
                  as="form"
                  sx={{
                      width: '80%',
                      maxWidth: '650px',
                      flexDirection: 'column',
                      justifyContent: 'center',
                  }}
                  onSubmit={handleSubmit(onSubmit)}
              >
              <Box py="2" width="100%">
                <Label htmlFor="firstName">First name</Label>
                <Input name="firstName" {...register('firstName', { required: true })} />
                {errors.firstName && (
                  <Box as="span" sx={{ color: 'red' }}>
                    {errors.firstName.message}
                  </Box>
                )}
              </Box>
              <Box py="2" width="100%">
                <Label htmlFor="lastName">Last name</Label>
                <Input name="lastName" {...register('lastName', { required: true })} />
                {errors.lastName && (
                  <Box as="span" sx={{ color: 'red' }}>
                    {errors.lastName.message}
                  </Box>
                )}
              </Box>
              <Box py="2">
                <Label htmlFor="email">Email</Label>
                <Input name="email" type="email" {...register('email', { required: true })} />
                {errors.email && (
                  <Box as="span" sx={{ color: 'red' }}>
                    {errors.email.message}
                  </Box>
                )}
              </Box>
              <Box py="2">
                <Label htmlFor="password">Password</Label>
                <Input name="password" type="password" {...register('password', { required: true })} />
                {errors.password && (
                  <Box as="span" sx={{ color: 'red' }}>
                    {errors.password.message}
                  </Box>
                )}
              </Box>
              <Box py="2">
                <Label htmlFor="passwordConfirmation">Password</Label>
                <Input
                  name="passwordConfirmation"
                  type="password"
                  {...register('passwordConfirmation', { required: true })}
                />
                {errors.passwordConfirmation && (
                  <Box as="span" sx={{ color: 'red' }}>
                    {errors.passwordConfirmation.message}
                  </Box>
                )}
              </Box>
              <Box py="2">
                <Label htmlFor="countryResidence">Country</Label>
                <Input name="countryResidence" type="text" {...register('countryResidence', { required: true })} />
                {errors.countryResidence && (
                  <Box as="span" sx={{ color: 'red' }}>
                    {errors.countryResidence.message}
                  </Box>
                )}
              </Box>
              <Box py="2">
                <Label htmlFor="currentCityResidence">City</Label>
                <Input
                  name="currentCityResidence"
                  type="text"
                  {...register('currentCityResidence', { required: true })}
                />
                {errors.currentCityResidence && (
                  <Box as="span" sx={{ color: 'red' }}>
                    {errors.currentCityResidence.message}
                  </Box>
                )}
              </Box>
              <Box py="2">
                <Label htmlFor="phone">Phone</Label>
                <InputMask
                  sx={{
                    boxSizing: 'border-box',
                    width: '100%',
                    padding: '8px',
                    display: 'block',
                    borderRadius: '4px',
                    lineHeight: 'inherit',
                    border: '1px solid',
                    backgroundColor: 'white',
                    appearance: 'none',
                    color: 'black',
                  }}
                  mask="999-999-9999"
                  name="phone"
                  type="phone"
                  {...register('phone', { required: false })}
                />
                {errors.phone && (
                  <Box as="span" sx={{ color: 'red' }}>
                    {errors.phone.message}
                  </Box>
                )}
              </Box>
              <Button variant="primary">Sign up</Button>
              <Box py="4" sx={{ textAlign: "center"}}>
                  <Text as="span">
                      Have an account?&nbsp;
                  </Text>
                  <Link to="/login" sx={{ color: 'yellow' }}>
                      Sign in
                  </Link>
              </Box>
            </Flex>
          )}
        </Flex>

      </Flex>
      <Footer />
    </Container>
  );
};

export default Registration;
