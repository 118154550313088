const theme = {
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  fonts: {
    body: 'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
    heading: 'sofia-pro',
    monospace: 'Menlo, monospace',
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 96],
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  colors: {
    black: '#000',
    blackBg: 'rgba(1, 22, 39, .7)',
    greenBg: 'rgba(0, 128, 0, .7)',
    redBg: 'rgba(255, 0, 0, .7)',
    red: '#F54236',
    green: '#1ed760',
    white: '#fff',
    yellow: '#ffcd04',
    yellow10: '#e8c547',
    yellow30: '#ffd644',
    yellowBg: 'rgba(255, 205, 4, .7)',
    gray: '#f6f6f6',
    gray10: '#A7A9AA',
    gray90: '#1f2028',
    gray60: '#4b4c53',
  },
  forms: {
    input: {
      backgroundColor: "white",
      color: 'black'
    }
  },
  links: {
    nav: {
      color: 'white',
      fontFamily: 'sofia-pro',
      textDecoration: 'underline 2.5em rgba(255, 255, 255, 0)',
      textTransform: 'uppercase',
      ':hover': {
        color: 'white',
        textDecoration: 'underline',
        textDecorationThickness: '2px',
        textUnderlineOffset: '0.4em',
        transition: 'text-decoration-color 750ms',
        transitionTimingFunction: 'ease-in',
      },
      ':hover:after': {
        transitionProperty: 'left right',
        transitionDuration: '0.75s',
        transitionTimingFunction: 'ease-out',
      },
    },
    button: {
      padding: '12px',
      display: 'block',
      color: 'white',
      fontFamily: 'sofia-pro',
      border: '1px solid white',
      borderRadius: '4px',
      textDecoration: 'none',
      textAlign: 'center',
      minWidth: '200px',
      ':hover': {
        color: 'black',
        backgroundColor: 'white',
      },
    },
    footer: {
      color: 'black',
      fontFamily: 'sofia-pro',
      textDecoration: 'none',
      textTransform: 'uppercase',
    },
  },
  buttons: {
    primary: {
      width: '100%',
      color: 'green',
      pointer: 'cursor',
      bg: 'white',
    },
  },

  menu: {
    display: 'block',
    position: 'relative',
    width: '14rem',
    listStyleType: 'none',
    'li:hover ul': {
      height: '200px',
      opacity: 1,
      transform: 'translateY(0)',
      zIndex: 2,
    },
  },
  dropdownMenu: {
    background: '#000',
    height: 0,
    left: 0,
    opacity: 0,
    position: 'absolute',
    transition: 'all .5s ease',
    top: '35px',
    width: '100%',
    listStyleType: 'none',
    borderBottom: '8px solid #EE2E24',
  },
  tab: {
    display: 'inline',
    width: '14rem',
    paddingInlineStart: 'unset',
    listStyleType: 'none',
    'li:hover ul': {
      opacity: 1,
      transform: 'translateY(0)',
      zIndex: 2,
    },
  },
  tabItem: {
    display: 'inline',
    listStyleType: 'none',
  },
  styles: {
    progress: {
      color: 'green',
      backgroundColor: 'white',
    },
    root: {
      backgroundColor: 'yellow',
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body',
    },
    h1: {
      color: 'black',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 5,
    },
    h2: {
      color: 'black',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 4,
    },
    h3: {
      color: 'black',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 3,
    },
    h4: {
      color: 'black',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 2,
    },
    h5: {
      color: 'black',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 1,
    },
    h6: {
      color: 'black',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 0,
    },
    p: {
      color: 'black',
      fontFamily: 'body',
      fontWeight: 'body',
      lineHeight: 'body',
    },
    a: {
      color: 'yellow',
    },
    pre: {
      fontFamily: 'monospace',
      overflowX: 'auto',
      code: {
        color: 'inherit',
      },
    },
    code: {
      fontFamily: 'monospace',
      fontSize: 'inherit',
    },
    table: {
      width: '100%',
      borderCollapse: 'separate',
      borderSpacing: 0,
    },
    th: {
      textAlign: 'left',
      borderBottomStyle: 'solid',
    },
    td: {
      textAlign: 'left',
      borderBottomStyle: 'solid',
    },
    img: {
      maxWidth: '100%',
    },
  },
};

export default theme;
