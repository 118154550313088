import React from 'react';
import { Button } from 'theme-ui';
import { useNavigate } from 'react-router-dom';
import { logoutUser } from '../services';

const LogoutButton = () => {
  const navigate = useNavigate();
  const handleLogout = async () => {
    await logoutUser();
    navigate('/home');
  };

  return (
    <Button variant="link" onClick={handleLogout}>
      logout
    </Button>
  );
};

export default LogoutButton;
