import React from 'react';
import { Flex, Heading, Box } from 'theme-ui';

const Schedule = () => {
  return (
    <Flex
      width="100%"
      sx={{
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: 'black',
        py: '4',
      }}
    >
      <Flex sx={{ flexDirection: 'column', width: '60%', maxWidth: '800px' }}>
        <Flex sx={{ width: '100%', justifyContent: 'center' }}>
          <Heading as="h2">Schedule</Heading>
        </Flex>
        <Flex sx={{ flexDirection: 'column', width: '100%', pt: '4' }}>
          <Flex sx={{ color: 'red', borderBottom: 'solid 1px', borderColor: 'red' }}>TUESDAY 28TH</Flex>

          <Flex
            sx={{
              flexDirection: 'row',
              color: 'red',
              borderBottom: 'solid 1px',
              borderColor: 'gray',
              py: '2',
            }}
          >
            <Flex sx={{ width: '30%' }}>7:00 AM - 8:15 AM</Flex>
            <Flex sx={{ flexDirection: 'column', width: '70%' }}>
              <Flex sx={{ color: 'white', width: '100%' }}>Registration</Flex>
              <Flex sx={{ color: 'gray10', width: '100%' }}>
                The biggest event of the year for creatives from all across the country. Be there as the design
                community.
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Schedule;
