import React, { useState, useEffect } from 'react';
import { Box, Heading, Flex } from 'theme-ui';

const CountDownTimer = ({ reunionStart }) => {
  const calculateTimeLeft = () => {
    const difference = new Date(reunionStart) - new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  const [year] = useState(new Date(reunionStart).getFullYear());

  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
  });

  return (
    <Flex sx={{ width: '100%', py: '6', flexDirection: ['column', 'row'] }}>
      <Flex p="4" sx={{ width: ['100%', '25%'] }}>
        <Box
          pt="6"
          sx={{
            borderRadius: '99rem',
            height: '20rem',
            width: '20rem',
            backgroundColor: 'yellowBg',
            textAlign: 'center',
          }}
        >
          <Heading as="h2">Days: {timeLeft.days ? timeLeft.days : 0}</Heading>
        </Box>
      </Flex>
      <Flex p="4" sx={{ width: ['100%', '25%'] }}>
        <Box
          pt="6"
          border="solid 2px #fff"
          sx={{
            width: '100%',
            borderRadius: '99rem',
            height: '20rem',
            backgroundColor: 'greenBg',
            width: '20rem',
            textAlign: 'center',
          }}
        >
          <Heading as="h2">Hours: {timeLeft.hours ? timeLeft.hours : 0}</Heading>
        </Box>
      </Flex>
      <Flex p="4" sx={{ width: ['100%', '25%'] }}>
        <Box
          pt="6"
          sx={{
            width: '100%',
            borderRadius: '99rem',
            height: '20rem',
            width: '20rem',
            backgroundColor: 'redBg',
            textAlign: 'center',
          }}
        >
          <Heading as="h2">Minutes: {timeLeft.minutes ? timeLeft.minutes : 0}</Heading>
        </Box>
      </Flex>
      <Flex p="4" sx={{ width: ['100%', '25%'] }}>
        <Box
          pt="6"
          sx={{
            width: '100%',
            borderRadius: '99rem',
            height: '20rem',
            width: '20rem',
            backgroundColor: 'blackBg',
            textAlign: 'center',
          }}
        >
          <Heading as="h2">Seconds: {timeLeft.seconds ? timeLeft.seconds : 0}</Heading>
        </Box>
      </Flex>
    </Flex>
  );
};

export default CountDownTimer;
