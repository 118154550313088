import axios from 'axios';
import { config } from '../config';

export const createContactInfos = async (data) => {
  try {
    return await axios.post(`${config.url.APIURL}/contact-infos`, data);
  } catch (error) {
    throw error;
  }
};
