import React from 'react';
import { Global, css } from '@emotion/react';
import { ThemeProvider } from 'theme-ui';
import ComingSoon from './pages/ComingSoon';
import Home from './pages/Home';
import Landing from './pages/Landing';
import Registration from './pages/Registration';
import RegSuccess from './pages/RegSuccess';
import Login from './pages/Login';
import ForgotPassword from './pages/ForgotPassword';
import SendConfirmationEmail from './pages/SendConfirmationEmail';
import ProfilePage from './pages/ProfilePage';

import { CurrentUserProvider } from './components/CurrentUser';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import theme from './theme';
import { QueryClient, QueryClientProvider } from 'react-query';
import sofiaProOTF from './assets/fonts/sofiapro-light.otf';

const queryClient = new QueryClient();

function App() {
  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <CurrentUserProvider>
          <Router>
            <Global
              styles={css`
                @font-face {
                  font-family: sofia-pro;
                  src: url(${sofiaProOTF}) format('opentype');
                }
              `}
            />
            <Routes>
              <Route path="/" element={<ComingSoon />} />
              <Route path="home" element={<Home />} />
              <Route path="register" element={<Registration />} />
              <Route path="reg-success" element={<RegSuccess />} />
              <Route path="landing" element={<Landing />} />
              <Route path="login" element={<Login />} />
              <Route path="forgot-password" element={<ForgotPassword />} />
              <Route path="resend-confirmation" element={<SendConfirmationEmail />} />
              <Route path="profile/*" element={<ProfilePage />} />
            </Routes>
          </Router>
        </CurrentUserProvider>
      </QueryClientProvider>
    </ThemeProvider>
  );
}

export default App;
