/** @jsxImportSource theme-ui */
import React from 'react';
import { Container, Flex } from 'theme-ui';
import { useForm } from 'react-hook-form';
import Logo from '../components/Logo';
import Header from '../components/Header';
import Footer from '../components/Footer';

const Landing = () => {
  return (
    <Container>
      <Flex width="100%" sx={{ flexDirection: 'column' }}>
        <Flex
          width="100%"
          sx={{
            flexDirection: 'column',
            justifyContent: 'flex-start',
            color: 'white',
            backgroundColor: 'gray90',
            minHeight: '70vh',
          }}
        >
          <Header />
          <Flex pt="5" px={['2', '4']} sx={{ justifyContent: 'flex-start' }}>
            <Logo color="white" />
          </Flex>
        </Flex>
      </Flex>
      <Footer />
    </Container>
  );
};

export default Landing;
