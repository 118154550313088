/** @jsxImportSource theme-ui */
import React from 'react';
import { Flex, useThemeUI } from 'theme-ui';
import { Link } from 'react-router-dom';

const Footer = () => {
  const { theme } = useThemeUI();

  const date = new Date().getFullYear();

  return (
    <Flex
      p="4"
      borderTop="solid 1px #000"
      borderColor="black"
      sx={{ flexDirection: 'column', borderTop: 'solid 1px #000' }}
      width="100%"
    >
      <Flex py="4" sx={{ flexDirection: 'row' }}>
        <Flex sx={{ width: '20%' }}>
          <Link to="/about" sx={{ ...theme.links.footer }}>
            About
          </Link>
        </Flex>
        <Flex sx={{ width: '20%' }}>
          <Link to="/register" sx={{ ...theme.links.footer }}>
            Registration
          </Link>
        </Flex>
        <Flex sx={{ width: '20%' }}>
          <Link to="/donations" sx={{ ...theme.links.footer }}>
            Donations
          </Link>
        </Flex>
      </Flex>
      <Flex>&#169; {date} thejwfr.com</Flex>
    </Flex>
  );
};

export default Footer;
