import React, { useEffect } from 'react';
import { Flex, Box, Heading } from 'theme-ui';
import * as leaflet from 'leaflet';
import { MapContainer, Marker, TileLayer, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

// Hack to fix leaflet issue with broken markers: https://stackoverflow.com/questions/41144319/leaflet-marker-not-found-production-env
delete leaflet.Icon.Default.prototype._getIconUrl;
leaflet.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

const Map = () => {
  const position = [33.7865, -84.3853];

  return (
    <Flex
      sx={{
        width: '100%',
        py: '4',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: 'white',
      }}
    >
      <Flex
        sx={{
          width: ['100%', '60%'],
          height: '450px',
          maxWidth: ['unset', '800px'],
          color: 'black',
        }}
      >
        <Box sx={{ position: 'absolute', height: '200px', width: ['400px', '800px'] }}>
          <MapContainer center={position} zoom={13} scrollWheelZoom={false} style={{ width: '100%', height: '400px' }}>
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker position={position}>
              <Popup>Four Seasons Hotel Atlanta</Popup>
            </Marker>
          </MapContainer>
        </Box>
      </Flex>
      <Flex
        sx={{
          width: ['100%', '800px'],
          py: '4',
          flexDirection: ['column', 'row'],
          alignItems: 'center',
        }}
      >
        <Flex
          sx={{
            width: ['100%', '50%'],
            maxWidth: '800px',
            color: 'black',
            flexDirection: 'column',
          }}
        >
          <Flex p={[2, 'unset']}>
            <Heading>Four Seasons Hotel Atlanta</Heading>
          </Flex>
          <Flex p={[2, 'unset']}>
            <Heading>75 14th St NE, Atlanta, GA</Heading>
          </Flex>
        </Flex>
        <Flex
          sx={{
            width: ['100%', '50%'],
            maxWidth: '800px',
            color: 'black',
            flexDirection: 'column',
          }}
        >
          <Flex p={[2, 'unset']}>
            <Heading>Phone</Heading>
          </Flex>
          <Flex p={[2, 'unset']}>
            <Heading>30309•(404) 881-9898</Heading>
          </Flex>
          <Flex p={[2, 'unset']}>
            <Heading>Info</Heading>
          </Flex>
          <Flex p={[2, 'unset']}>info@email.com</Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Map;
