import React from 'react';
import { Flex, Heading } from 'theme-ui';
import Logo from '../components/Logo';

const CountDownHeader = ({ where, when }) => {
  return (
    <>
      <Flex pt="5" px={['2', '4']} sx={{ justifyContent: 'flex-start' }}>
        <Logo color="white" />
      </Flex>
      <Flex width="100%" px="4" sx={{ flexDirection: ['column', 'row'] }}>
        <Flex
          pt="4"
          pl={[null, '4']}
          sx={{
            width: ['100%','50%'],
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Flex sx={{ color: 'gray10' }}>WHEN</Flex>
          <Flex>
            <Heading as="h1">{ when }</Heading>
          </Flex>
        </Flex>
        <Flex
          pt="4"
          sx={{
            width: ['100%','50%'],
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Flex sx={{ color: 'gray10' }}>WHERE</Flex>
          <Flex>
            <Heading as="h1">{ where }</Heading>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default CountDownHeader;
