import React from 'react';
import { NavLink, Box } from 'theme-ui';
import LogoutButton from './LogoutButton';

const AccountMenu = () => {
  return (
    <Box as="ul" variant="menu">
      <Box as="li">
        <NavLink
          href="/account"
          sx={{
            py: '2',
          }}
        >
          Account
        </NavLink>
        <Box as="ul" variant="dropdownMenu">
          <Box as="li">
            <LogoutButton />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AccountMenu;
