/** @jsxImportSource theme-ui */
import React from 'react';
import { Flex, useThemeUI } from 'theme-ui';
import JwfrLogo from './JwfrLogo';
import AccountMenu from './AccountMenu';
import { useCurrentUser } from '../components/CurrentUser';
import { Link } from 'react-router-dom';

const Header = () => {
  const { isAuthenticated } = useCurrentUser();

  const { theme } = useThemeUI();
  const { nav, button } = theme.links;
  return (
    <Flex pt="4" px={['2', '5']} sx={{ flexDirection: 'row' }}>
      <Flex sx={{ width: '20%', justifyContent: 'flex-start' }}>
        <Link to="/home">
          <JwfrLogo color="yellow" size="50" />
        </Link>
      </Flex>
      {isAuthenticated ? (
        <>
          <Flex sx={{ width: '20%' }}>
            <Link sx={{ ...nav }} to="#">
              Meet the family
            </Link>
          </Flex>
          <Flex sx={{ width: '20%' }}>
            <Link sx={{ ...nav }} to="#">
              Reunions
            </Link>
          </Flex>
          <Flex sx={{ width: '20%' }}>
            <Link sx={{ ...nav }} to="#">
              Memorials
            </Link>
          </Flex>
          <Flex sx={{ width: '20%' }}>
            <Link sx={{ ...nav }} to="#">
              Family Tree
            </Link>
          </Flex>
        </>
      ) : (
        <>
          <Flex sx={{ width: '20%' }}>
            <Link sx={{ ...nav }} to="/about">
              About
            </Link>
          </Flex>
          <Flex sx={{ width: '20%' }}>
            <Link sx={{ ...nav }} to="/register">
              Registration
            </Link>
          </Flex>
          <Flex sx={{ width: '20%' }}>
            <Link sx={{ ...nav }} to="/donations">
              Donations
            </Link>
          </Flex>
        </>
      )}
      <Flex sx={{ width: '20%', justifyContent: 'flex-end' }}>
        {isAuthenticated ? (
          <AccountMenu />
        ) : (
          <Link sx={{ ...button }} to="/login">
            login
          </Link>
        )}
      </Flex>
    </Flex>
  );
};

export default Header;
