/** @jsxImportSource theme-ui */
import React, { useState, useEffect } from 'react';
import { Container, Flex } from 'theme-ui';
import { useForm } from 'react-hook-form';
import atlantaImage from '../assets/images/atlanta-georgia-skyline-cr-getty-2.jpeg';
import CountDownHeader from '../components/CountDownHeader';
import CountDownTimer from '../components/CountDownTImer';
import Schedule from '../components/Schedule';
import Map from '../components/Map';
import Header from '../components/Header';
import { useQuery } from 'react-query';
import Footer from '../components/Footer';
import { fetchHomepage } from '../services';

const initialState = {
 when: "--",
 reunionStart: "--",
 Heading: "--",
 heroImage: ""
};

const Home = () => {
  const { isSuccess, isLoading, data } = useQuery('homepage', () => fetchHomepage());

    return (
        <Container>
            <Flex width="100%" sx={{ flexDirection: 'column' }}>
                <Flex
                    width="100%"
                    sx={{
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        color: 'white',
                        backgroundColor: 'gray90',
                        background: `url(${atlantaImage})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        minHeight: '70vh',
                    }}
                >
                    <Header />
                    { isLoading && (
                        <>
                            <CountDownHeader where={initialState.Heading} when={initialState.when}  />
                            <CountDownTimer reunionStart={initialState.reunionStart} />
                        </>
                        )
                    }
                    { isSuccess && (
                        <>
                            <CountDownHeader where={data.data.Heading} when={data.data.when}  />
                            <CountDownTimer reunionStart={data.data.reunionStart} />
                        </>
                        )
                    }
                    <Schedule />
                    <Map />
                </Flex>
            </Flex>
            <Footer />
        </Container>
    );

};

export default Home;
