import React from 'react';
import PropTypes from 'prop-types';
import theme from '../theme';

const SuccessCheck = ({ color, size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      x="0px"
      y="0px"
      width={size}
      height={size}
      viewBox="0 0 1000 1000"
      enableBackground="new 0 0 1000 1000"
    >
      <g fill={theme.colors[color] ?? color} stroke="none">
        <path d="M500,9.5C229.4,9.5,10,229.1,10,500s219.4,490.5,490,490.5c270.6,0,490-219.6,490-490.5C990,229.1,770.6,9.5,500,9.5L500,9.5z M500,949.1C252.3,949.1,51.4,748,51.4,500C51.4,252,252.3,50.9,500,50.9C747.8,50.9,948.6,252,948.6,500C948.6,748,747.8,949.1,500,949.1L500,949.1z M432.2,621.3L302.8,490.9l-43.1,43.5l172.5,173.8l366.5-369.3l-43.1-43.4L432.2,621.3L432.2,621.3z" />
      </g>
    </svg>
  );
};

SuccessCheck.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
};

SuccessCheck.defaultProps = {
  color: '#FFF',
  size: '400',
};

export default SuccessCheck;
