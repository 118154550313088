import React from 'react';
import { Flex, Box, Heading } from 'theme-ui';

const ProfileDetails = ({ user }) => {
  return (
    <>
      <Flex sx={{ p: '2', color: 'gray10' }}>
        <Heading as="h4">Contact information</Heading>
      </Flex>
      <Flex sx={{ p: '2' }}>
        <Box as="ul">
          <Box as="li">Phone: {user.phoneNumber}</Box>
          <Box as="li">
            Address: {user.currentCityResidence}, {user.countryResidence}
          </Box>
          <Box as="li">Email: {user.email}</Box>
        </Box>
      </Flex>
      <Flex sx={{ p: '2', color: 'gray10' }}>
        <Heading as="h4">Basic information</Heading>
      </Flex>
      <Flex sx={{ p: '2' }}>
        <Box as="ul">
          <Box as="li">Birthday:</Box>
          <Box as="li">Gender:</Box>
          <Box as="li">Occupation:</Box>
        </Box>
      </Flex>
    </>
  );
};

export default ProfileDetails;
