import React, { useEffect, useState } from 'react';
import { Container, Flex, Heading, Progress } from 'theme-ui';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import Logo from '../components/Logo';
import SuccessCheck from '../components/SuccessCheck';

const useTimeoutRedirect = () => {
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      navigate('/login');
    }, 5000);
  }, []);
};

const useProgress = () => {
  const [progress, setState] = useState(1 / 250);

  useEffect(() => {
    const interval = setInterval(() => {
      setState(progress + 1 / 250);
    }, 15);
    return () => clearInterval(interval);
  }, [progress]);

  return progress;
};

const RegSuccess = () => {
  const progress = useProgress();
  useTimeoutRedirect();

  return (
    <Container>
      <Progress max={1} value={progress} />
      <Flex width="100%" sx={{ flexDirection: 'column' }}>
        <Flex
          width="100%"
          sx={{
            flexDirection: 'column',
            justifyContent: 'flex-start',
            color: 'white',
            backgroundColor: 'gray90',
            minHeight: '20vh',
          }}
        >
          <Flex pt="5" px={['2', '4']} sx={{ justifyContent: 'flex-start' }}>
            <Logo color="white" />
          </Flex>
        </Flex>
        <Flex
          width="100%"
          p="4"
          sx={{
            flexDirection: 'column',
            justifyContent: 'center',
            backgroundColor: '#1ed760',
          }}
        >
          <Flex
            sx={{
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            <Heading as="h1" sx={{ color: 'white', fontSize: '4em' }}>
              Success!
            </Heading>
          </Flex>
          <Flex
            sx={{
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            <Heading sx={{ color: 'white' }}>You were registered</Heading>
          </Flex>
          <Flex
            sx={{
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            <Heading sx={{ color: 'white' }}>Check your email to confirm and continue.</Heading>
          </Flex>
          <Flex
            sx={{
              flexDirection: 'column',
              py: '4',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
            <SuccessCheck />
          </Flex>
        </Flex>
      </Flex>
    </Container>
  );
};

export default RegSuccess;
