/** @jsxImportSource theme-ui */
import React, { useState, useEffect } from 'react';
import { Box, Button, Container, Flex, Heading, Label, Input } from 'theme-ui';
import { useForm } from 'react-hook-form';
import atlantaImage from '../assets/images/atlanta-georgia-skyline-cr-getty-2.jpeg';
import CountDownHeader from '../components/CountDownHeader';
import CountDownTimer from '../components/CountDownTImer';
import JwfrLogo from '../components/JwfrLogo';
import { useMutation, useQuery } from 'react-query';
import { createContactInfos } from '../services';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import InputMask from 'react-input-mask';
import { fetchHomepage } from '../services';

const schema = yup
  .object({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    email: yup.string().email().required(),
    phone: yup
      .string()
      .trim()
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        'incorrect format'
      )
      .required(),
  })
  .required();

const initialState = {
    when: "--",
    reunionStart: "--",
    Heading: "--",
    heroImage: ""
};

const ComingSoon = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

    const { isSuccess: isSuccess, data } = useQuery('homepage', () => fetchHomepage());
    const [reunion, setReunion] = useState(initialState);

    useEffect(() => {
        if (isSuccess) {
            setReunion(data.data);
        }
    });

  const date = new Date().getFullYear();
  const { mutate, isSuccess: isContactSuccess } = useMutation('createContact', (data) => createContactInfos(data));
  const onSubmit = ({ email, firstName, lastName, phone }) => {
    mutate({ email, firstName, lastName, phone });
  };

  return (
    <Container>
      <Flex width="100%" sx={{ flexDirection: 'column' }}>
        <Flex
          width="100%"
          sx={{
            flexDirection: 'column',
            justifyContent: 'flex-start',
            color: 'white',
            backgroundColor: 'gray90',
            background: `url(${atlantaImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            minHeight: '70vh',
          }}
        >
          <Flex pt="4" px={['2', '5']} sx={{ flexDirection: 'column', justifyContent: 'flex-start' }}>
            <JwfrLogo color="yellow" size="50" />
          </Flex>
            <CountDownHeader where={reunion.Heading} when={reunion.when}  />
            <CountDownTimer reunionStart={reunion.reunionStart} />
        </Flex>
      </Flex>
      <Flex sx={{ flexDirection: ['column', 'row'] }}>
        <Flex
          p="6"
          sx={{
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: ['100%', '50%'],
          }}
        >
          <Flex>
            <Heading as="h1">Sign up for updates</Heading>
          </Flex>
          <Flex>
            <JwfrLogo size="500" color="gray" />
          </Flex>
        </Flex>
        <Flex
          p={['2', '6']}
          sx={{
            width: ['100%', '50%'],
            backgroundColor: 'black',
            color: 'white',
          }}
        >
          <Box as="form" sx={{ width: '100%' }} onSubmit={handleSubmit(onSubmit)}>
            {isContactSuccess && (
              <Box py="2" width="100%">
                Thanks your information was added
              </Box>
            )}

            <Box py="2" width="100%">
              <Label htmlFor="firstName">First name</Label>
              <Input name="firstName" {...register('firstName', { required: true })} />
              {errors.firstName && <Box as="span">This field is required</Box>}
            </Box>
            <Box py="2" width="100%">
              <Label htmlFor="lastName">Last name</Label>
              <Input name="lastName" {...register('lastName', { required: true })} />
              {errors.lastName && <Box as="span">This field is required</Box>}
            </Box>
            <Box py="2">
              <Label htmlFor="email">Email</Label>
              <Input name="email" type="email" {...register('email', { required: true })} />
              {errors.email && <Box as="span">This field is required</Box>}
            </Box>
            <Box py="2">
              <Label htmlFor="phone">Phone</Label>
              <InputMask
                sx={{
                  boxSizing: 'border-box',
                  width: '100%',
                  padding: '8px',
                  display: 'block',
                  borderRadius: '4px',
                  lineHeight: 'inherit',
                  border: '1px solid',
                  backgroundColor: 'white',
                  appearance: 'none',
                  color: 'black',
                }}
                mask="999-999-9999"
                name="phone"
                type="phone"
                {...register('phone', { required: false })}
              />
              {errors.phone && <Box as="span">Invalid format</Box>}
            </Box>
            <Button variant="primary">Sign up</Button>
          </Box>
        </Flex>
      </Flex>
      <Flex
        p="4"
        borderTop="solid 1px #000"
        borderColor="black"
        sx={{ flexDirection: 'column', borderTop: 'solid 1px #000' }}
        width="100%"
      >
        <Flex>&#169; {date} thejwfr.com</Flex>
      </Flex>
    </Container>
  );
};

export default ComingSoon;
