const prod = {
  url: {
    APIURL: 'https://api.thejwfr.com',
  },
};

const dev = {
  url: {
    APIURL: 'http://localhost:1337',
  },
};

export const config = process.env.NODE_ENV === 'development' || process.env.NODE_ENV  === 'test' ? dev : prod;
