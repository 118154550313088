/** @jsxImportSource theme-ui */
import React from 'react';
import { Box, Container, Flex, Heading, Avatar, Text, Spinner } from 'theme-ui';
import { useForm } from 'react-hook-form';
import Logo from '../components/Logo';
import Header from '../components/Header';
import { useMutation } from 'react-query';
import { useCurrentUser } from '../components/CurrentUser';
import Footer from '../components/Footer';
import ProfileDetails from '../components/ProfileDetails';
import ProfileEditDetails from '../components/ProfileEditDetails';
import ProfileImage from '../components/ProfileImage';
import { Routes, Route, Link } from 'react-router-dom';

const ProfilePage = () => {
  const { data: user } = useCurrentUser();

  return (
    <Container>
      <Flex width="100%" sx={{ flexDirection: 'column' }}>
        <Flex
          width="100%"
          sx={{
            flexDirection: 'column',
            justifyContent: 'flex-start',
            color: 'white',
            backgroundColor: 'gray90',
            minHeight: '30vh',
          }}
        >
          <Header />
          <Flex pt="5" px={['2', '4']} sx={{ justifyContent: 'flex-start' }}>
            <Logo color="white" />
          </Flex>
          <Flex
            px={['2', '4']}
            sx={{
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {user ? (
              <Flex sx={{ flexDirection: 'row', width: '100%', px: '5' }}>
                <Flex sx={{ flexDirection: 'column', width: '40%', pb: '4' }}>
                  <ProfileImage user={user} />
                </Flex>
                <Flex sx={{ flexDirection: 'column', width: '60%', pb: '4' }}>
                  <Flex sx={{ flexDirection: 'row', alignItems: 'flex-start' }}>
                    <Flex sx={{ px: '2' }}>
                      <Text as="span" sx={{ fontSize: '4' }}>
                        {user.firstName} {user.lastName}
                      </Text>
                    </Flex>
                    <Flex sx={{ px: '2', alignItems: 'flex-end' }}>
                      <Text as="span" sx={{ fontSize: '2' }}>
                        {user.currentCityResidence}
                      </Text>
                    </Flex>
                  </Flex>
                  <Flex sx={{ flexDirection: 'column', px: '2', py: '4' }}>
                    <Flex py="1">
                      <Heading as="h3" sx={{ color: 'yellow' }}>
                        Fun Facts About Me
                      </Heading>
                    </Flex>
                    <Flex py="2">
                      <Box as="p">
                        Lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum
                        lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum
                        lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum.
                      </Box>
                    </Flex>
                  </Flex>
                  <Flex sx={{ flexDirection: 'column' }}>
                    <Flex
                      sx={{
                        p: '2',
                        borderBottom: 'solid 1px',
                        borderColor: 'gray',
                      }}
                    >
                      <Box as="ul" variant="tab">
                        <Box as="li" variant="tabItem" p="2">
                          <Link to="/profile">Details</Link>
                        </Box>
                        <Box as="li" variant="tabItem" p="2">
                          <Link to="/profile/edit">Edit</Link>
                        </Box>
                      </Box>
                    </Flex>
                    <Routes>
                      <Route path="/" element={<ProfileDetails user={user} />} />
                      <Route path="edit" element={<ProfileEditDetails user={user} />} />
                    </Routes>
                  </Flex>
                </Flex>
              </Flex>
            ) : (
              <Spinner />
            )}
          </Flex>
        </Flex>
      </Flex>
      <Footer />
    </Container>
  );
};

export default ProfilePage;
