/** @jsxImportSource theme-ui */
import React, { useMemo, useEffect, useState } from 'react';
import { Flex, Box, Heading, Input, Label, Button, Radio } from 'theme-ui';
import { useForm, Controller } from 'react-hook-form';
import { useMutation } from 'react-query';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import InputMask from 'react-input-mask';
import { useNavigate } from 'react-router-dom';
import { parse, isDate } from 'date-fns';
import { updateUser } from '../services';
import countryList from 'react-select-country-list';
import Select from 'react-select';

function parseDateString(value, originalValue) {
  const parsedDate = isDate(originalValue) ? originalValue : parse(originalValue, 'yyyy-MM-dd', new Date());

  return parsedDate;
}

const schema = yup
  .object({
    email: yup.string().email().required('Email is required'),
    currentCityResidence: yup.string().required(),
    countryResidence: yup
      .object({
        value: yup.string().required(),
        label: yup.string().required(),
      })
      .required(),
    address: yup.string(),
    phoneNumber: yup
      .string() // validate phone number based on country else us by default
      .trim()
      .matches(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/, 'Incorrect phone number format')
      .required(),
    gender: yup.string().oneOf(['male', 'female']),
    birthday: yup.string(),
    occupation: yup.string(),
  })
  .required();

const ProfileEditDetails = ({ user }) => {
 console.log('User: ', user);
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
        ...user,
        countryResidence: {
            value: countryList().getValue(user.countryResidence),
            label: user.countryResidence
        }
    },
    resolver: yupResolver(schema)
  });

  const options = useMemo(() => countryList().getData(), []);
  const navigate = useNavigate();
  const { mutate } = useMutation('updateUser', (data) => updateUser(data));
  const onSubmit = ({ email, firstName, lastName, phoneNumber, currentCityResidence, countryResidence, password }) => {
    mutate(
      {
        id: user.id,
        email,
        firstName,
        lastName,
        phoneNumber,
        currentCityResidence,
        countryResidence: countryResidence.label,
        password,
      },
      {
        onSuccess: () => {
          navigate('/profile');
        },
      }
    );
  };

  return (
    <Box as="form" onSubmit={handleSubmit(onSubmit)}>
      <Flex sx={{ p: '2', color: 'gray10' }}>
        <Heading as="h4">Contact information</Heading>
      </Flex>
      <Flex sx={{ p: '2' }}>
        <Box>
          <Box p="2">
            <Label>
              Phone:
                <Controller
                    name="phoneNumber"
                    control={control}
                    render={({ field }) => <InputMask
                        sx={{
                            boxSizing: 'border-box',
                            width: '100%',
                            padding: '8px',
                            display: 'block',
                            borderRadius: '4px',
                            lineHeight: 'inherit',
                            border: '1px solid',
                            backgroundColor: 'transparent',
                            appearance: 'none',
                            color: 'inherit',
                        }}
                        mask="999-999-9999"
                        type="phone"
                        {...field}
                    />}
                />

            </Label>
            {errors.phone && (
              <Box as="span" sx={{ color: 'red' }}>
                {errors.phone.message}
              </Box>
            )}
          </Box>
          <Box p="2">
            <Label>
              City:{' '}
              <Input
                name="currentCityResidence"
                {...register('currentCityResidence')}
              />
            </Label>
            {errors.currentCityResidence && (
              <Box as="span" sx={{ color: 'red' }}>
                {errors.currentCityResidence.message}
              </Box>
            )}
            <Controller
              name="countryResidence"
              control={control}
              render={({ field }) => <Select {...field} options={options} />}
            />
            {errors.address && (
              <Box as="span" sx={{ color: 'red' }}>
                {errors.address.message}
              </Box>
            )}
          </Box>
          <Box p="2">
            <Label>
              Email: <Input {...register('email')} />
            </Label>
            {errors.email && (
              <Box as="span" sx={{ color: 'red' }}>
                {errors.email.message}
              </Box>
            )}
          </Box>
        </Box>
      </Flex>
      <Flex sx={{ p: '2', color: 'gray10' }}>
        <Heading as="h4">Basic information</Heading>
      </Flex>
      <Flex sx={{ p: '2' }}>
        <Box>
          <Box p="2">
            <Label>
              Birthday:
              <InputMask
                sx={{
                  boxSizing: 'border-box',
                  width: '100%',
                  padding: '8px',
                  display: 'block',
                  borderRadius: '4px',
                  lineHeight: 'inherit',
                  border: '1px solid',
                  backgroundColor: 'transparent',
                  appearance: 'none',
                  color: 'inherit',
                }}
                mask="99/99/9999"
                name="birthday"
                type="date"
                {...register('birthday')}
              />
            </Label>
            {errors.birthday && (
              <Box as="span" sx={{ color: 'red' }}>
                {errors.birthday.message}
              </Box>
            )}
          </Box>
          <Box p="2">
            Gender:
            <Label>
              <Radio name="gender" value="male" defaultChecked={true} {...register('gender')} />
              Male
            </Label>
            <Label>
              <Radio name="gender" value="female" {...register('gender')} />
              Female
            </Label>
            {errors.gender && (
              <Box as="span" sx={{ color: 'red' }}>
                {errors.gender.message}
              </Box>
            )}
          </Box>
          <Box p="2">
            <Label>Occupation: </Label>
            <Input name="occupation" {...register('occuption')} />
            {errors.occupation && (
              <Box as="span" sx={{ color: 'red' }}>
                {errors.occupation.message}
              </Box>
            )}
          </Box>
        </Box>
      </Flex>
      <Flex px="4" py="4">
        <Button>Update Info</Button>
      </Flex>
    </Box>
  );
};

export default ProfileEditDetails;
