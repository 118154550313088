/** @jsxImportSource theme-ui */
import React, { useState, useEffect } from 'react';
import { Container, Flex, Box, Label, Input, Text, Button, Heading } from 'theme-ui';
import { useForm } from 'react-hook-form';
import JwfrLogo from '../components/JwfrLogo';
import Logo from '../components/Logo';
import { useMutation } from 'react-query';
import Footer from '../components/Footer';
import { sendEmailConfirmation } from '../services';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate, Link } from 'react-router-dom';
import { useCurrentUser } from '../components/CurrentUser';

const schema = yup
  .object({
    email: yup.string().email().required('Please enter your email'),
  })
  .required();

const SendConfirmationEmail = () => {
  const [respError, setRespError] = useState(null);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const { mutate } = useMutation('loginUser', (data) => sendEmailConfirmation(data));
  const onSubmit = ({ email }) => {
    mutate(
      { email },
      {
        onSuccess: () => {
          setRespError(null);
          navigate('/landing');
        },
        onError: (error) => {
          if (error.response) {
            setRespError(error.response.data.message[0]?.messages[0]?.message ?? error.response.data.message);
          }
        },
      }
    );
  };

  const useRedirect = (navigate) => {
    const { isAuthenticated } = useCurrentUser();

      if (isAuthenticated) {
        navigate('/landing');
        return null;
      }
  };

  return (
    <Container>
      <Flex width="100%" sx={{ flexDirection: 'column' }}>
        <Flex
          width="100%"
          sx={{
            flexDirection: 'column',
            justifyContent: 'flex-start',
            color: 'white',
            backgroundColor: 'gray90',
            backgroundSize: 'cover',
            minHeight: '70vh',
          }}
        >
          <Flex pt="4" px={['2', '5']} sx={{ flexDirection: 'row' }}>
            <Flex sx={{ width: '20%', justifyContent: 'flex-start' }}>
              <Link to="/home">
                <JwfrLogo color="yellow" size="50" />
              </Link>
            </Flex>
          </Flex>
          <Flex pt="5" px={['2', '4']} sx={{ justifyContent: 'flex-start' }}>
            <Logo color="white" />
          </Flex>
          <Flex
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <Flex>
              <Heading>Resend Email Confirmation</Heading>
            </Flex>
            {respError && (
              <Flex
                sx={{
                  pt: '2',
                  width: '80%',
                  maxWidth: '650px',
                  justifyContent: 'center',
                  color: 'red',
                }}
              >
                {respError}
              </Flex>
            )}
            {mutate?.error?.message && (
              <Flex
                sx={{
                  pt: '2',
                  width: '80%',
                  maxWidth: '650px',
                  justifyContent: 'center',
                  color: 'red',
                }}
              >
                {mutate.error.message}
              </Flex>
            )}
            <Flex
              as="form"
              sx={{
                width: '80%',
                maxWidth: '650px',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
              onSubmit={handleSubmit(onSubmit)}
            >
              <Box py="2">
                <Label htmlFor="email">Email</Label>
                <Input name="email" type="email" {...register('email', { required: true })} />
                {errors.email && (
                  <Box as="span" sx={{ color: 'red' }}>
                    {errors.email.message}
                  </Box>
                )}
              </Box>
              <Button variant="primary">Resend confirmation</Button>
            </Flex>
            <Flex pt="4" sx={{ flexDirection: 'column' }}>
              <Flex>
                <Text as="span">
                  Have an account?&nbsp;
                </Text>
                <Link to="/login" sx={{ color: 'yellow' }}>
                  Sign in
                </Link>
              </Flex>
              <Flex py="2" width="100%">
                <Text as="span">Trouble signing in? &nbsp;</Text>
                <Link to="/forgot-password" sx={{ color: 'yellow' }}>
                  Reset password
                </Link>
              </Flex>
            </Flex>
            <Flex sx={{ flexDirection: 'column', pb: '4' }}>
              <Flex>
                <Text as="span">&nbsp; Need an account? &nbsp;</Text>
                <Link to="/register" sx={{ color: 'yellow' }}>
                  Register
                </Link>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Footer />
    </Container>
  );
};

export default SendConfirmationEmail;
