import React, { useState, useEffect } from 'react';
import { Avatar, Flex, Box, Button } from 'theme-ui';
import avatar from '../assets/svgs/1547510251.svg';
import ImageUploading from 'react-images-uploading';
import { uploadProfileImage, fetchUserById } from '../services/user';
import { useQuery } from 'react-query';
import { getApiUrl } from '../utils/url-helpers';

const useUser = ({ id }) => useQuery('userInfo', async () => fetchUserById(id));

const ProfileImage = ({ user }) => {
  const { isSuccess, isError, data } = useUser(user);
  const [image, setImage] = useState([{ dataUrl: avatar }]);

  useEffect(() => {
    if (isSuccess) {
      const { data: info } = data;
      setImage([{ dataUrl: getApiUrl(info.profilePic.url) }]);
    }
  }, [isSuccess]);

  const onChange = (imageList, addUpdateIndex) => {
    setImage(imageList);
    uploadProfileImage({
      ref: 'user',
      files: imageList[0].file,
      refId: user.id,
      field: 'profilePic',
    });
  };

  return (
    <ImageUploading
      value={image}
      onChange={onChange}
      maxNumber={2}
      dataURLKey="dataUrl"
      acceptType={['jpg', 'jpeg', 'gif', 'png']}
    >
      {({ imageList, onImageUpload, onImageRemoveAll, onImageUpdate, onImageRemove, isDragging, dragProps }) => {
        console.log('imageList: ', imageList);
        return (
          <Box
            sx={{
              width: '300px',
              height: '300px',
              border: 'solid 1px',
              borderColor: 'white',
            }}
          >
            <Button onClick={onImageUpload} {...dragProps}>
              <Avatar src={image[0].dataUrl} sx={{ width: '300px', height: '300px' }} />
            </Button>
          </Box>
        );
      }}
    </ImageUploading>
  );
};

export default ProfileImage;
