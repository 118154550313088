import axios from 'axios';
import { config } from '../config';

export const registerUser = async (data) => {
  try {
    return await axios.post(`${config.url.APIURL}/auth/local/register`, data);
  } catch (error) {
    throw error;
  }
};

export const updateUser = async (data) => {
  try {
    return await axios.put(`${config.url.APIURL}/users/${data.id}`, data, {
      withCredentials: true,
    });
  } catch (error) {
    throw error;
  }
};

export const uploadProfileImage = async (data) => {
  try {
    const bodyFormData = new FormData();
    console.log('data: ', data);
    bodyFormData.append('ref', data.ref);
    bodyFormData.append('files', data.files);
    bodyFormData.append('refId', data.refId);
    bodyFormData.append('field', data.field);
    bodyFormData.append('source', 'users-permissions');

    return await axios.post(`${config.url.APIURL}/upload`, bodyFormData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      withCredentials: true,
    });
  } catch (error) {
    throw error;
  }
};

export const sendEmailConfirmation = async (data) => {
  try {
    return await axios.post(`${config.url.APIURL}/auth/send-email-confirmation`, data);
  } catch (error) {
    throw error;
  }
};

export const forgotPassword = async (data) => {
  try {
    return await axios.post(`${config.url.APIURL}/auth/forgot-password`, data);
  } catch (error) {
    throw error;
  }
};

export const resetPassword = async (data) => {
  try {
    return await axios.post(`${config.url.APIURL}/auth/reset-password`, data);
  } catch (error) {
    throw error;
  }
};

export const loginUser = async (data) => {
  const { identifier, password } = data;
  try {
    return await axios.post(`${config.url.APIURL}/auth/local`, {
      identifier,
      password,
    },
    {
      withCredentials: true
    });
  } catch (error) {
    throw error;
  }
};

export const logoutUser = async () => {
  try {
    return await axios.post(`${config.url.APIURL}/logout`, null, {
      withCredentials: true,
    });
  } catch (error) {
    throw error;
  }
};

export const fetchUser = async () => {
  try {
    return await axios.get(`${config.url.APIURL}/users/me`, {
      withCredentials: true,
    });
  } catch (error) {
    throw error;
  }
};

export const fetchUserById = async (id) => {
  try {
    return await axios.get(`${config.url.APIURL}/users/${id}`, {
      withCredentials: true,
    });
  } catch (error) {
    throw error;
  }
};
