import React, { useEffect, useReducer, useContext, createContext } from 'react';
import { useQuery } from 'react-query';
import { fetchUser } from '../services';

const CurrentUserStateContext = createContext();
const CurrentUserDispatchContext = createContext();

const reducer = (state, action) => {
  switch (action.type) {
    case 'LOGIN':
      return { ...action.user, isAuthenticated: true };
    case 'LOGOUT':
      return { isAuthenticated: false };
    default:
      throw new Error(`unknown action ${action.type}`);
  }
};

const useUserDispatch = () => {
  const [state, dispatch] = useReducer(reducer, { isAuthenticated: false });
  const query = useQuery('fetchUser', fetchUser);

  useEffect(() => {
    const getUser = () => {
      if (query.isSuccess) {
        dispatch({ type: 'LOGIN', user: query.data });
        return;
      }
    };
    getUser();
  }, [query.isSuccess]);

  return [state, dispatch];
};

export const CurrentUserProvider = ({ children }) => {
  const [state, dispatch] = useUserDispatch();
  return (
    <CurrentUserDispatchContext.Provider value={dispatch}>
      <CurrentUserStateContext.Provider value={state}>{children}</CurrentUserStateContext.Provider>
    </CurrentUserDispatchContext.Provider>
  );
};

export const useCurrentUser = () => useContext(CurrentUserStateContext);
export const useDispatchCurrentUser = () => useContext(CurrentUserDispatchContext);
